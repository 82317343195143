import { SINGER } from "./singers";

export const awadhi = [
  {
    id: 1,
    link: "https://www.youtube.com/watch?v=9NG-vbNoPnk",
    title: "Saiyan Mile Larkaiya",
    singer: SINGER.MALINI_AWASTHI,
  },
];
