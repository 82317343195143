import { SINGER } from "./singers";

export const bhojpuriSongs = [
  {
    id: 1,
    title: "Nai Jhulni Ke Chhaiya",
    link: "https://youtu.be/HjAUlsi9QwA?si=ZGbTBEIICTEuaHUi",
    singer: SINGER.DINESH_LAL_YADAV,
  },
];
