import { SINGER } from "./singers";

export const kabir = [
  {
    id: 1,
    title: "Jogan Ban Jaungi Sayyan Tore Kaaran",
    link: "https://www.youtube.com/watch?v=v6VZXoXJ4w8",
    singer: SINGER.USTAD_FARID_AYAZ,
  },
  {
    id: 2,
    title: "Shab e Hijr Voh Dam ba Dam Yaad Aaye by",
    link: "https://www.youtube.com/watch?v=aa8onCQryfQ",
    singer: SINGER.USTAD_FARID_AYAZ,
  },
];
