import { SINGER } from "./singers";

export const maithili = [
  {
    id: 1,
    link: "https://www.youtube.com/watch?v=hU89gTAOSiM",
    title: "Ram Ji Se Puche Janakpur ki Naari",
    singer: SINGER.MAITHALI_THAKUR,
  },
  {
    id: 2,
    link: "https://www.youtube.com/watch?v=qbfL1kWVze0",
    title: "Nagri ho Ayodhya si",
    singer: SINGER.MAITHALI_THAKUR,
  },
  {
    id: 3,
    link: "https://www.youtube.com/watch?v=1pYELmwMzmQ",
    title: "Tum Utho Siya Sringar Karo",
    singer: SINGER.MAITHALI_THAKUR,
  },
  // {
  //   id: 1,
  //   link: "",
  //   title: "",
  //   singer: SINGER.MAITHALI_THAKUR,
  // },
];

//  {
//     id: 1,
//     link: "https://www.youtube.com/watch?v=hU89gTAOSiM",
//     title: "Ram Ji Se Puche Janakpur ki Naari",
//     singer: SINGER.MAITHALI_THAKUR,
//   },
